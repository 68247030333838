import React, { useEffect, useState } from "react";
import { useGetCompanies } from "./hooks/useGetCompanies";
import { IFrame } from "./components/IFrame";

import "./style.scss";

export const TestPage = () => {
  const [isAppReady, setAppReady] = useState(false);
  const [companyUUID, setCompanyUUID] = useState(localStorage.getItem("UUID") || "593471e4-2666-476f-933a-a8bb79d11254");
  const [listingId, setListingId] = useState(localStorage.getItem("listingId") || "75b12587-eb93-4333-8b30-53762e2523e8");
  const [dataSource, setDataSource] = useState(localStorage.getItem("dataSource") || "test.");
  const [language, setLanguage] = useState(localStorage.getItem("lang") || "en");
  const [version, setVersion] = useState(localStorage.getItem("version") || "v1");
  const [exchange, setExchange] = useState(localStorage.getItem("exchange") || "QSE");
  const [widgets, setWidgets] = useState();
  const [companies] = useGetCompanies(
    setAppReady,
    setCompanyUUID,
    setListingId,
    dataSource,
    exchange
  );
  
  const updateExchange = (exchange) => {
    localStorage.setItem("exchange", exchange);

    setExchange(exchange);
  }

  const updateDataSource = (dataSource) => {
    localStorage.setItem("dataSource", dataSource);

    setDataSource(dataSource);
  }

  const updateVersion = (version) => {
    localStorage.setItem("version", version);

    setVersion(version);
  }

  const updateCompany = (company) => {
    localStorage.setItem("UUID", company.companyId);
    localStorage.setItem("listingId", company.listingId);

    setCompanyUUID(company.companyId);
    setListingId(company.listingId);
  }

  const updateLanguage = (language) => {
    localStorage.setItem("lang", language);

    setLanguage(language);
  }
  
  useEffect(() => {
    const url = `https://${dataSource === "production" ? "" : dataSource}irp.atnmo.com/${version}/widget`;

    const widgetDataSource = dataSource === "test." ? "test" : dataSource === "staging." ? "staging" : "prod"

    const queryParam = `UUID=${companyUUID}&listingId=${listingId}&lang=${language}&dataSource=${widgetDataSource}`;

    const widgetsV1 = [
      {
        title: "Company Snapshot",
        src: `${url}/company-snapshot?${queryParam}`,
        width: "100%",
      },
      {
        title: "Fact Sheet Table",
        src: `${url}/fact-sheet-table?${queryParam}`,
        width: "100%",
      },
      {
        title: "Fact Sheet Charts",
        src: `${url}/fact-sheet-charts?${queryParam}`,
        width: "100%",
      },
      {
        title: "Stock Activity - Simple",
        src: `${url}/stock-activity-simple?${queryParam}`,
        width: "100%",
      },
      {
        title: "Stock Activity - Advanced",
        src: `${url}/stock-activity-advanced?${queryParam}`,
        width: "100%",
      },
      {
        title: "Corporate Actions",
        src: `${url}/corporate-actions?${queryParam}`,
        width: "100%",
      },
      {
        title: "Corporate News",
        src: `${url}/corporate-news?${queryParam}`,
        width: "100%",
      },
      {
        title: "Company Financials",
        src: `${url}/company-financials?${queryParam}`,
        width: "100%",
      },
      {
        title: "Investment Calculator",
        src: `${url}/investment-calculator?${queryParam}`,
        width: "100%",
      },
      {
        title: "Share Price",
        src: `${url}/share-price?${queryParam}`,
        width: "250px",
      },
      {
        title: "Email Subscription",
        src: `${url}/email-subscription?${queryParam}`,
        width: "100%",
      },
      {
        title: "Stock Ticker",
        src: `${url}/stock-ticker?${queryParam}`,
        width: "350px",
      },
    ];
  
    const widgetsV2 = [
      {
        title: "Company Snapshot",
        src: `${url}/company-snapshot?${queryParam}`,
        width: "100%",
      },
      {
        title: "Fact Sheet Table",
        src: `${url}/fact-sheet-table?${queryParam}`,
        width: "100%",
      },
      {
        title: "Fact Sheet Charts",
        src: `${url}/fact-sheet-charts?${queryParam}`,
        width: "100%",
      },
      {
        title: "Stock Activity Simple",
        src: `${url}/stock-activity-simple?${queryParam}`,
        width: "100%",
      },
      {
        title: "Stock Activity Advanced",
        src: `${url}/stock-activity-advanced?${queryParam}`,
        width: "100%",
      },
      {
        title: "Corporate Actions",
        src: `${url}/corporate-actions?${queryParam}`,
        width: "100%",
      },
      {
        title: "Corporate News",
        src: `${url}/corporate-news?${queryParam}`,
        width: "100%",
      },
      {
        title: "Company Financials",
        src: `${url}/company-financials?${queryParam}`,
        width: "100%",
      },
      {
        title: "Email Subscription",
        src: `${url}/email-subscription?${queryParam}`,
        width: "100%",
      },
      {
        title: "Stock Ticker",
        src: `${url}/stock-ticker?${queryParam}`,
        width: "100%",
      },
      {
        title: "Peer Group Analysis",
        src: `${url}/peer-group-analysis?${queryParam}`,
        width: "100%",
      },
      {
        title: "Performance",
        src: `${url}/performance?${queryParam}`,
        width: "100%",
      },
      {
        title: "Share Series",
        src: `${url}/share-series?${queryParam}`,
        width: "100%",
      },
    ];

    if (version === "v2") {
      setWidgets(widgetsV2);
    } else {
      setWidgets(widgetsV1);
    }
  }, [version, companyUUID, listingId, dataSource, exchange, companies, language]);

  return (
    <div className="test-page-wrapper">
      <div className="test-page-content-wrapper">
        {isAppReady ? (
          <>
            <div className="options-wrapper">
              <div>
                Version:
                <select
                  className="test-select"
                  onChange={(e) => {
                    updateVersion(e.target.value);
                  }}
                  value={version}
                >
                  <option value="v1">v1</option>
                  <option value="v2">v2</option>
                </select>
              </div>
              <div>
                Data Source:
                <select
                  className="test-select"
                  onChange={(e) => {
                    updateDataSource(e.target.value);
                  }}
                  value={dataSource}
                >
                  <option value="test.">test</option>
                  <option value="staging.">staging</option>
                  <option value="production">production</option>
                </select>
              </div>
              <div>
                Exchange:
                <select
                  className="test-select"
                  onChange={(e) => {
                    updateExchange(e.target.value);
                  }}
                  value={exchange}
                >
                  <option value="QSE">QSE</option>
                  <option value="QEVM">QEVM</option>
                  <option value="SE">SE</option>
                </select>
              </div>
              <div>
                Company:
                <select
                  className="test-select"
                  onChange={(e) => {
                    updateCompany(JSON.parse(e.target.value));
                  }}
                  value={JSON.stringify({
                    companyId: companyUUID,
                    listingId
                  })}
                >
                  {companies.map((item, i) => (
                    <option
                      key={i}
                      value={JSON.stringify({
                        companyId: item.companyId,
                        listingId: item.listingId,
                      })}
                    >
                      {`${item.listingSymbol}  ${item.name}`}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                Language:
                <select
                  className="test-select"
                  onChange={(e) => {
                    updateLanguage(e.target.value);
                  }}
                  value={language}
                >
                  <option value="en">English</option>
                  <option value="ar">Arabic</option>
                </select>
              </div>
            </div>
            <>
              {widgets.map((item) => {
                return (
                  <div className="iframe-wrapper" key={item.src}>
                    <h1 className="chart-tittle">{item.title}</h1>
                    <IFrame src={item.src} width={item.width} />
                  </div>
                );
              })}
            </>
          </>
        ) : null}
      </div>
    </div>
  );
};
