import React from "react";
import IframeResizer from "iframe-resizer-react";

export const IFrame = ({src, width}) => {
  return (
    <IframeResizer
      src={src}
      width={width}
      frameBorder="0"
      style={{ border: "1px solid #e0e0e0"}}
      enablePublicMethods={true}
    />
  );
};
