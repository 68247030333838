import React from 'react';
import './App.css';
import { TestPage } from "./TestPage";

function App() {
  return (
    <div className="App" >
      <TestPage />
    </div>
  );
}

export default App;
