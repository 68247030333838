import { useEffect, useState } from "react";
import { http } from "../../utils/api";

export const useGetCompanies = (setAppReady, setCompanyUUID, setListingId, dataSource, exchange) => {
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    const getCompanies = async () => {
      const url = `https://${dataSource === "production" ? "" : dataSource}api.meds.atnmo.com/news-feed/listings`;

      const config = await http.get(url).then((response) => response.data);
      const listNullSymbolConpanies = [];
      config.forEach((element) => {
        if (element.listingSymbol === null) {
          element.listingSymbol = "";
          listNullSymbolConpanies.push(element);
        }
      });
      const updateResponse = config.filter((a) => a.listingSymbol !== "" && a.exchangeSymbol === exchange);
      updateResponse.sort((a, b) => a.listingSymbol.localeCompare(b.listingSymbol));
      updateResponse.sort((a, b) => a.listingSymbol.localeCompare(b.listingSymbol));
      listNullSymbolConpanies.forEach((element) => {
        updateResponse.push(element);
      });

      if (updateResponse && updateResponse[0]) {
        setCompanyUUID(updateResponse[0].companyId)
        setListingId(updateResponse[0].listingId)
      }
      setCompanies(updateResponse);
      setAppReady(true);
    };
    getCompanies();
  }, [setAppReady, setCompanyUUID, setListingId, dataSource, exchange]);

  return [companies];
};
